import { hasWindow } from '@services/http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import * as CsrHttpService from '@http/csr-http.service';
import {
    GET_INVESTMENT_STATS_URL,
    EDIT_IINTVESTMENT_STATS_DETAILS_URL,
} from '@services/urls/investmetStats';

export const investmentStats = (ctx = null) => {
    const url = GET_INVESTMENT_STATS_URL();
    if (hasWindow()) {
        return CsrHttpService.getWithOutAuth(url);
    }
    return SsrHttpService.getWithOutAuth(url, ctx);
};

export const editInvestmentDetails = (id, data, ctx = null) => {
    const url = EDIT_IINTVESTMENT_STATS_DETAILS_URL(id);
    if (hasWindow()) {
        return CsrHttpService.putWithAuth(url, data);
    }
    return SsrHttpService.putWithAuth(url, data, ctx);
};
