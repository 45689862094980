import Text from '@atoms/Text';
import StatItems from './statItems';
import { useEffect, useState } from 'react';
import { investmentStats } from '@services/investment-stats.service';
import {
    getHomePageStats,
    revalidateInvestmentStats,
} from '@helpers/miscelleanous';
import moment from 'moment';
import texts from '@components/Homepage/en.json';
import { cn } from '@ui/helpers/utils';

function Stats({
    statsResponse = null,
    className = 'bg-basicBlack',
    paddingClass = 'p-5 md:py-20',
    titleText = texts?.StatsTitleText,
    getStats = (investmentsStats) => getHomePageStats(investmentsStats),
    titleWidth = 'xl:w-3/12',
    statsWidth = 'w-full xl:w-9/12',
    isHomepage = false,
}) {
    const [stats, setStats] = useState([]);

    useEffect(() => {
        getInvestmentStats();
    }, []);

    const getInvestmentStats = async () => {
        if (!statsResponse) {
            let investmentsRecords = JSON.parse(
                localStorage.getItem('investmentStats')
            );
            const expiry = investmentsRecords
                ? moment(investmentsRecords.expiry)
                : null;
            if (!(investmentsRecords && expiry.isAfter(moment()))) {
                investmentsRecords = await investmentStats();
                if (investmentsRecords?.status) {
                    investmentsRecords =
                        revalidateInvestmentStats(investmentsRecords);
                    const stats = getStats(investmentsRecords);
                    setStats(stats);
                }
            } else {
                const stats = getStats(investmentsRecords);
                setStats(stats);
            }
            return;
        }
        if (statsResponse?.status) {
            const response = revalidateInvestmentStats(statsResponse);
            const result = getStats(response?.status ? response : null);
            setStats(result);
        }
    };

    return (
        <section className={className}>
            <div className="xl:ml-10 px-0 overflow-hidden">
                <div
                    className={`xl:mx-0 flex-col xl:flex xl:flex-row items-center ${paddingClass} justify-between`}
                >
                    <div className={titleWidth}>
                        <Text
                            htmlTag="h2"
                            isInnerHtml
                            content={titleText}
                            className={cn(
                                'p5-medium md:p3-medium xl:p1-medium text-basicWhite',
                                {
                                    'text-primary-500': isHomepage,
                                }
                            )}
                        />
                    </div>
                    <div className={`pt-5 xl:pt-0 ${statsWidth}`}>
                        <StatItems stats={stats} isHomepage={isHomepage} />
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Stats;
